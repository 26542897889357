/* eslint-disable react/no-unescaped-entities */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "@apollo/react-hooks";
import get from "lodash/get";
import { useEffect } from "react";
import uuid from "uuid";
import { withRouter, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import ClientMenu from "../../../../components/UI/WizardNav/ClientMenu";
import { searchBaseModels } from "../../../../resolvers/query/base";
import { Select, DatePicker } from "../../../../components/UI";
import { valueHandler } from "./helpers";
import { updateUser as updateUserAction } from "../../../../store/user/user/user_actions";

const Sport = () => {
    const { t } = useTranslation();
    const { updateUser, updateFlag } = useSelector(s => s.userState);
    const dispatch = useDispatch();

    const sportInfoState = get(updateUser, "user_sport", {});
    const sportSpecialistInfoState = get(updateUser, "sport_specialist", {});

    let { displayErrors } = updateUser.user_sport;
    displayErrors = displayErrors || updateUser.displayErrors;
    let sportComponentErrors = get(updateUser, "user_sport.errors", {});
    let sportSpecialistComponentErrors = get(updateUser, "sport_specialist.errors", {});
    /**
     *
     * @param {Event} e
     */
    const displayErrorsFunc = e => {
        let keys = [...Object.keys(sportComponentErrors), ...Object.keys(sportSpecialistComponentErrors)];
        if (keys.length > 0) {
            e.preventDefault();
            dispatch(updateUserAction({ name: "user_sport.displayErrors", value: true }));
        }
    };

    const [showSportSpecialist, toggleSportSpecialist] = useState(updateFlag);

    let selectItems = useQuery(searchBaseModels, {
        variables: {
            sport: true,
            sport_status: true,
            training_frequency: true,
            training_status: true,
            so_card: true,
            sport_function: true
        }
    });

    useEffect(() => {
        let PID = get(sportSpecialistInfoState, "specialist_PID", false);
        if (!PID) {
            dispatch(updateUserAction({ name: "sport_specialist.specialist_PID", value: uuid() }));
        }
    }, []);

    let buttons = () => (
        <div className="col-md-12 d-flex justify-content-end">
            <div className="btn-right">
                <Link to="private" className="btn btn-cancel btn-180">
                    {t("back.label")}
                </Link>
                <Link onClick={displayErrorsFunc} to="work" className="btn btn-primary btn-180">
                    {t("next.label")}
                </Link>
            </div>
        </div>
    );

    const yearChangeHandler = e => {
        e.preventDefault();
        let val = parseInt(e.target.value);
        if (val && val >= 0 && val <= 100) {
            e.target.value = val;
        } else {
            e.target.value = parseInt(get(sportInfoState, "years", "")) || 0;
        }
    };

    return (
        <>
            <ClientMenu componentName={"sport"} componentNumber={5} clickHandler={displayErrorsFunc} />
            <div className="container-fluid">
                <form
                    onChange={e => {
                        let req = valueHandler(e);
                        dispatch(updateUserAction({ name: "user_sport." + req.name, value: req.value }));
                    }}
                >
                    <div className="client-form">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="client-form-title">
                                    <h1>{t("sport.label")}</h1>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>{t("sport.label")}</label>
                                    <Select
                                        select={e => e}
                                        name="sport"
                                        // text="Select sport"
                                        items={get(selectItems, "data.searchBaseModels.Sport", [])}
                                        selectedItem={get(sportInfoState, "sport.id", "").toString()}
                                    />
                                    {displayErrors ? (
                                        <span style={{ color: "red" }}>{sportComponentErrors.sport}</span>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>{t("status.label")}</label>
                                    <Select
                                        select={e => e}
                                        name="sport_status"
                                        // text="Select sport status"
                                        items={get(selectItems, "data.searchBaseModels.SportStatus", [])}
                                        selectedItem={get(sportInfoState, "sport_status.id", "").toString()}
                                    />
                                    {displayErrors ? (
                                        <span style={{ color: "red" }}>{sportComponentErrors.sport_status}</span>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>{t("years.label")} (0 - 100)</label>
                                    <input
                                        onChange={e => yearChangeHandler(e)}
                                        defaultValue={get(sportInfoState, "years", "")}
                                        type="text"
                                        name="years"
                                        className="form-control"
                                    />
                                    {displayErrors ? (
                                        <span style={{ color: "red" }}>{sportComponentErrors.years}</span>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>{t("training_frequency.label")}</label>
                                    <Select
                                        select={e => e}
                                        name="training_frequency"
                                        // text="Select training frequency"
                                        items={get(selectItems, "data.searchBaseModels.TrainingFrequency", [])}
                                        selectedItem={get(sportInfoState, "training_frequency.id", "").toString()}
                                    />
                                    {displayErrors ? (
                                        <span style={{ color: "red" }}>{sportComponentErrors.training_frequency}</span>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>{t("training_status.label")}</label>
                                    <Select
                                        select={e => e}
                                        // text="Select training status"
                                        name="training_status"
                                        items={get(selectItems, "data.searchBaseModels.TrainingStatus", [])}
                                        selectedItem={get(sportInfoState, "training_status.id", "").toString()}
                                    />
                                    {displayErrors ? (
                                        <span style={{ color: "red" }}>{sportComponentErrors.training_status}</span>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>{t("discipline.label")}</label>
                                    <input
                                        defaultValue={get(sportInfoState, "discipline", "")}
                                        type="text"
                                        name="discipline"
                                        className="form-control"
                                    />
                                    {displayErrors ? (
                                        <span style={{ color: "red" }}>{sportComponentErrors.discipline}</span>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>{t("suqad.label")}</label>
                                    <input
                                        defaultValue={get(sportInfoState, "squad", "")}
                                        type="text"
                                        name="squad"
                                        className="form-control"
                                    />
                                    {displayErrors ? (
                                        <span style={{ color: "red" }}>{sportComponentErrors.squad}</span>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>{t("training_group.label")}</label>
                                    <input
                                        defaultValue={get(sportInfoState, "training_group", "")}
                                        type="text"
                                        name="training_group"
                                        className="form-control"
                                    />
                                    {displayErrors ? (
                                        <span style={{ color: "red" }}>{sportComponentErrors.training_group}</span>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>{t("so_card.label")}</label>
                                    <Select
                                        select={e => e}
                                        name="SOCard"
                                        // text="Select so card"
                                        items={get(selectItems, "data.searchBaseModels.SOCard", [])}
                                        selectedItem={get(sportInfoState, "SOCard.id", "").toString()}
                                    />
                                    {displayErrors ? (
                                        <span style={{ color: "red" }}>{sportComponentErrors.SOCard}</span>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>{t("association.label")}</label>
                                    <input
                                        defaultValue={get(sportInfoState, "association", "")}
                                        type="text"
                                        name="association"
                                        className="form-control"
                                    />
                                    {displayErrors ? (
                                        <span style={{ color: "red" }}>{sportComponentErrors.association}</span>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>{t("remark.label")}</label>
                                    <textarea
                                        defaultValue={get(sportInfoState, "comment", "")}
                                        name="comment"
                                        className="form-control"
                                        rows="5"
                                    ></textarea>
                                    {displayErrors ? (
                                        <span style={{ color: "red" }}>{sportComponentErrors.comment}</span>
                                    ) : null}
                                </div>
                            </div>
                            {showSportSpecialist ? (
                                <>
                                    <div className="col-md-12">
                                        <div className="client-more">
                                            <a
                                                href=""
                                                onClick={e => {
                                                    e.preventDefault ? e.preventDefault() : (e.returnValue = false);
                                                    toggleSportSpecialist(!showSportSpecialist);
                                                }}
                                            >
                                                <i className={"icon-add"}></i>
                                                {t("add_specialist.label")}
                                            </a>
                                        </div>
                                    </div>
                                    {buttons()}
                                </>
                            ) : null}
                        </div>
                    </div>
                </form>
                {!showSportSpecialist ? (
                    <form
                        onChange={e => {
                            let req = valueHandler(e);
                            dispatch(updateUserAction({ name: "sport_specialist." + req.name, value: req.value }));
                        }}
                    >
                        <div className="client-form">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="client-more client-more-cancel">
                                        <h1>{t("sport_specialist.label")}</h1>
                                        <a
                                            href=""
                                            onClick={e => {
                                                e.preventDefault ? e.preventDefault() : (e.returnValue = false);
                                                toggleSportSpecialist(!showSportSpecialist);
                                                // setSportSpecialisInfoState({ ...sportSpecialisInfoState, show: false });
                                            }}
                                        >
                                            <i className="icon-cancel"></i>
                                            {t("hide.label")}
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>{t("pid.label")}</label>
                                        <input
                                            defaultValue={get(sportSpecialistInfoState, "specialist_PID", "")}
                                            disabled
                                            type="text"
                                            name="specialist_PID"
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>{t("name.label")}</label>
                                        <input
                                            defaultValue={get(sportSpecialistInfoState, "name", "")}
                                            type="text"
                                            name="name"
                                            className="form-control"
                                        />
                                        {displayErrors ? (
                                            <span style={{ color: "red" }}>{sportSpecialistComponentErrors.name}</span>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>{t("function.label")}</label>
                                        <Select
                                            name="specialist_function"
                                            select={e => e}
                                            items={get(selectItems, "data.searchBaseModels.SportFunction", [])}
                                            selectedItem={get(
                                                sportSpecialistInfoState,
                                                "specialist_function.id",
                                                ""
                                            ).toString()}
                                            // text="Select function"
                                        />
                                        {displayErrors ? (
                                            <span style={{ color: "red" }}>
                                                {sportSpecialistComponentErrors.specialist_function}
                                            </span>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group date-custom date-custom-label">
                                        <label>{t("cooperation_since.label")}</label>
                                        <DatePicker
                                            date={get(sportSpecialistInfoState, "cooperation_since", "")}
                                            select={e =>
                                                dispatch(
                                                    updateUserAction({
                                                        name: "sport_specialist.cooperation_since",
                                                        value: e
                                                    })
                                                )
                                            }
                                        />
                                        {displayErrors ? (
                                            <span style={{ color: "red" }}>{sportSpecialistComponentErrors.date}</span>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>{t("email.label")}</label>
                                        <input
                                            defaultValue={get(sportSpecialistInfoState, "email", "")}
                                            type="email"
                                            name="email"
                                            className="form-control"
                                        />
                                        {displayErrors ? (
                                            <span style={{ color: "red" }}>{sportSpecialistComponentErrors.email}</span>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>{t("phone.label")}</label>
                                        <input
                                            defaultValue={get(sportSpecialistInfoState, "mobile", "")}
                                            type="text"
                                            name="mobile"
                                            className="form-control"
                                        />
                                        {displayErrors ? (
                                            <span style={{ color: "red" }}>
                                                {sportSpecialistComponentErrors.mobile}
                                            </span>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>{t("phone_alternative.label")}</label>
                                        <input
                                            defaultValue={get(sportSpecialistInfoState, "mobile_alternative", "")}
                                            type="text"
                                            name="mobile_alternative"
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>{t("remark.label")}</label>
                                        <input
                                            defaultValue={get(sportSpecialistInfoState, "comments", "")}
                                            type="text"
                                            name="comments"
                                            className="form-control"
                                        />
                                        {displayErrors ? (
                                            <span style={{ color: "red" }}>
                                                {sportSpecialistComponentErrors.comments}
                                            </span>
                                        ) : null}
                                    </div>
                                </div>
                                {!showSportSpecialist ? <>{buttons()}</> : null}
                            </div>
                        </div>
                    </form>
                ) : null}
            </div>
        </>
    );
};

Sport.propTypes = {
    changeMasterStateHandler: PropTypes.func,
    formChangeHandler: PropTypes.func,
    sportState: PropTypes.object,
    sportSpecialistState: PropTypes.object,
    history: PropTypes.object,
    update: PropTypes.bool
};

export default withRouter(Sport);
