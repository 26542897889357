/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import get from "lodash/get";
import { useQuery } from "@apollo/react-hooks";
import { useSelector } from "react-redux";
import uuid from "uuid";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import ClientMenu from "../../../../components/UI/WizardNav/ClientMenu";
import { searchBaseModels } from "../../../../resolvers/query/base";
import { Select } from "../../../../components/UI";
import { valueHandler } from "./helpers";
import { updateUser as updateUserAction } from "../../../../store/user/user/user_actions";

const Work = () => {
  const { t } = useTranslation();
  const { updateUser } = useSelector((s) => s.userState);
  const dispatch = useDispatch();

  const workInfoState = get(updateUser, "work", {});

  let selectItems = useQuery(searchBaseModels, {
    variables: { country: true },
  });

  let { displayErrors } = updateUser.work;
  displayErrors = displayErrors || updateUser.displayErrors;
  let workComponentErrors = get(workInfoState, "errors", {});
  /**
   *
   * @param {Event} e
   */
  const displayErrorsFunc = (e) => {
    let keys = [...Object.keys(workComponentErrors)];
    if (keys.length > 0) {
      e.preventDefault();
      dispatch(updateUserAction({ name: "work.displayErrors", value: true }));
    }
  };

  useEffect(() => {
    let PID = get(workInfoState, "PID", false);
    if (!PID) {
      dispatch(updateUserAction({ name: "work.PIDcompany", value: uuid() }));
    }
  }, []);
  return (
    <>
      <ClientMenu
        componentName={"work"}
        componentNumber={6}
        clickHandler={displayErrorsFunc}
      />
      <div className="container-fluid">
        <form
          onChange={(e) => {
            let req = valueHandler(e);
            dispatch(
              updateUserAction({ name: "work." + req.name, value: req.value })
            );
          }}
        >
          <div className="client-form">
            <div className="row">
              <div className="col-md-12">
                <div className="client-form-title">
                  <h1>{t("work.label")}</h1>
                </div>
              </div>
              <div className="col-md-12">
                <div className="title-form">
                  <h5>{t("companies.label")}</h5>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>{t("pid.label")}</label>
                  <input
                    defaultValue={get(workInfoState, "PIDcompany", "")}
                    type="text"
                    disabled
                    name="PIDcompany"
                    className="form-control"
                  />
                  {displayErrors ? (
                    <span style={{ color: "red" }}>
                      {workComponentErrors.PID}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>{t("job_name.label")}</label>
                  <input
                    defaultValue={get(workInfoState, "job_name", "")}
                    type="text"
                    name="job_name"
                    className="form-control"
                  />
                  {displayErrors ? (
                    <span style={{ color: "red" }}>
                      {workComponentErrors.job_name}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>{t("company.label")}</label>
                  <input
                    defaultValue={get(workInfoState, "company_name", "")}
                    type="text"
                    name="company_name"
                    className="form-control"
                  />
                  {displayErrors ? (
                    <span style={{ color: "red" }}>
                      {workComponentErrors.company_name}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>{t("department.label")}</label>
                  <input
                    defaultValue={get(workInfoState, "department", "")}
                    type="text"
                    name="department"
                    className="form-control"
                  />
                  {displayErrors ? (
                    <span style={{ color: "red" }}>
                      {workComponentErrors.department}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>{t("position.label")}</label>
                  <input
                    defaultValue={get(workInfoState, "position", "")}
                    type="text"
                    name="position"
                    className="form-control"
                  />
                  {displayErrors ? (
                    <span style={{ color: "red" }}>
                      {workComponentErrors.position}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>{t("street.label")}</label>
                  <input
                    defaultValue={get(workInfoState, "street", "")}
                    type="text"
                    name="street"
                    className="form-control"
                  />
                  {displayErrors ? (
                    <span style={{ color: "red" }}>
                      {workComponentErrors.street}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>{t("additional_address.label")}</label>
                  <input
                    defaultValue={get(workInfoState, "additional_address", "")}
                    type="text"
                    name="additional_address"
                    className="form-control"
                  />
                  {displayErrors ? (
                    <span style={{ color: "red" }}>
                      {workComponentErrors.additional_address}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>{t("postal.label")}</label>
                  <input
                    defaultValue={get(workInfoState, "postal", "")}
                    type="text"
                    name="postal"
                    className="form-control"
                  />
                  {displayErrors ? (
                    <span style={{ color: "red" }}>
                      {workComponentErrors.postal}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>{t("city.label")}</label>
                  <input
                    defaultValue={get(workInfoState, "city", "")}
                    type="text"
                    name="city"
                    className="form-control"
                  />
                  {displayErrors ? (
                    <span style={{ color: "red" }}>
                      {workComponentErrors.city}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>{t("country.label")}</label>
                  <Select
                    // text="Select state"
                    items={get(
                      selectItems,
                      "data.searchBaseModels.Country",
                      []
                    )}
                    select={(e) => e}
                    selectedItem={get(
                      workInfoState,
                      "country.id",
                      ""
                    ).toString()}
                    name="country"
                  />
                  {displayErrors ? (
                    <span style={{ color: "red" }}>
                      {workComponentErrors.country}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-12">
                <div className="title-form">
                  <h5>{t("company.label")}</h5>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>{t("phone.label")}</label>
                  <input
                    defaultValue={get(workInfoState, "mobile", "")}
                    type="text"
                    name="mobile"
                    className="form-control"
                  />
                  {displayErrors ? (
                    <span style={{ color: "red" }}>
                      {workComponentErrors.mobile}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>{t("landline.label")}</label>
                  <input
                    defaultValue={get(workInfoState, "landline", "")}
                    type="text"
                    name="landline"
                    className="form-control"
                  />
                  {displayErrors ? (
                    <span style={{ color: "red" }}>
                      {workComponentErrors.landline}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>{t("email.label")}</label>
                  <input
                    defaultValue={get(workInfoState, "email", "")}
                    type="email"
                    name="email"
                    className="form-control"
                  />
                  {displayErrors ? (
                    <span style={{ color: "red" }}>
                      {workComponentErrors.email}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>{t("website.label")}</label>
                  <input
                    defaultValue={get(workInfoState, "website", "")}
                    type="text"
                    name="website"
                    className="form-control"
                  />
                  {displayErrors ? (
                    <span style={{ color: "red" }}>
                      {workComponentErrors.website}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>{t("fax.label")}</label>
                  <input
                    defaultValue={get(workInfoState, "fax", "")}
                    type="text"
                    name="fax"
                    className="form-control"
                  />
                  {displayErrors ? (
                    <span style={{ color: "red" }}>
                      {workComponentErrors.fax}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-12 d-flex justify-content-end">
                <div className="btn-right">
                  <Link to="sport" className="btn btn-cancel btn-180">
                    {t("back.label")}
                  </Link>
                  <Link
                    onClick={displayErrorsFunc}
                    to="medicine"
                    className="btn btn-primary btn-180"
                  >
                    {t("next.label")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

Work.propTypes = {
  changeMasterStateHandler: PropTypes.func,
  formChangeHandler: PropTypes.func,
  state: PropTypes.object,
  history: PropTypes.object,
};

export default withRouter(Work);
