/* eslint-disable react/no-unescaped-entities */
import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useQuery } from "@apollo/react-hooks";
import get from "lodash/get";
import { withRouter, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import ClientMenu from "../../../../components/UI/WizardNav/ClientMenu";
import { searchBaseModels } from "../../../../resolvers/query/base";
import { Select, Modal } from "../../../../components/UI";
import { valueHandler } from "./helpers";
import { submitFormHandler } from "../event-handlers";
import {
  displayErrors as displayErrorsRedux,
  user,
  updateUser as updateUserAction,
} from "../../../../store/user/user/user_actions";
import ClientDisclaimer from "../../../../components/UI/Modal/ClientDisclaimer";

const Medicine = (props) => {
  const { t } = useTranslation();
  //Redux state
  const { singleUser, updateUser, updateFlag, secondModal } = useSelector(
    (s) => s.userState
  );

  //This component state
  const medicineInfoState = get(updateUser, "medicine", {});
  const medExpertInfoState = get(updateUser, "med_expert", {});
  const problemInfoState = get(updateUser, "problem", {});
  const diagnoseInfoState = get(updateUser, "diagnose", {});

  let { displayErrors } = updateUser;
  let medicineComponentErrors = get(updateUser, "medicine.errors", {});
  let medExpertComponentErrors = get(updateUser, "med_expert.errors", {});
  let problemComponentErrors = get(updateUser, "problem.errors", {});
  let diagnoseComponentErrors = get(updateUser, "diagnose.errors", {});

  const [modal, toggleModal] = useState(false);
  const [showMedExpert, toggleMedExpert] = useState(updateFlag);
  const [showDiagnose, toggleDiagnose] = useState(updateFlag);
  const [showProblem, toggleProblem] = useState(updateFlag);

  let selectItems = useQuery(searchBaseModels, {
    variables: { medical_function: true },
  });
  const dispatch = useDispatch();

  let buttons = () => (
    <div className="col-md-12 d-flex justify-content-end">
      <div className="btn-right">
        <Link to="work" className="btn btn-cancel btn-180">
          {t("back.label")}
        </Link>
        <a
          onClick={(e) => {
            e.preventDefault();
            if (!updateUser.isAllValid) {
              dispatch(displayErrorsRedux());
              if (!get(updateUser, "administration.isValid", false)) {
                props.history.push("administration");
              } else if (!get(updateUser, "personal.isValid", false)) {
                props.history.push("personal");
              } else if (!get(updateUser, "address.isValid", false)) {
                props.history.push("personal");
              } else if (!get(updateUser, "private_info.isValid", false)) {
                props.history.push("private");
              } else if (!get(updateUser, "finance.isValid", false)) {
                props.history.push("finance");
              } else if (!get(updateUser, "ahv.isValid", false)) {
                props.history.push("finance");
              } else if (!get(updateUser, "user_sport.isValid", false)) {
                props.history.push("sport");
              } else if (
                !get(updateUser, "user_sport.sport_specialist.isValid", false)
              ) {
                props.history.push("sport");
              } else if (!get(updateUser, "work.isValid", false)) {
                props.history.push("work");
              }
            } else {
              toggleModal(true);
            }
          }}
          href="/"
          className="btn btn-primary btn-180"
        >
          {t("finish.label")}
        </a>
      </div>
    </div>
  );

  return (
    <>
      <ClientMenu componentName={"medicine"} componentNumber={7} />
      <div className="container-fluid">
        <form
          onChange={(e) => {
            let req = valueHandler(e);
            dispatch(
              updateUserAction({
                name: "medicine." + req.name,
                value: req.value,
              })
            );
          }}
        >
          <div className="client-form">
            <div className="row">
              <div className="col-md-12">
                <div className="client-form-title">
                  <h1>{t("medicine.label")}</h1>
                </div>
              </div>
              <div className="col-md-12">
                <div className="title-form">
                  <h5>{t("health_insurance.label")}</h5>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>{t("kk_kvg.label")}</label>
                  <input
                    defaultValue={get(medicineInfoState, "KK_KVG", "")}
                    type="text"
                    name="KK_KVG"
                    className="form-control"
                  />
                  {displayErrors ? (
                    <span style={{ color: "red" }}>
                      {medicineComponentErrors.KK_KVG}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>{t("kk_addition.label")}</label>
                  <input
                    defaultValue={get(medicineInfoState, "KK_zusatz", "")}
                    type="text"
                    name="KK_zusatz"
                    className="form-control"
                  />
                  {displayErrors ? (
                    <span style={{ color: "red" }}>
                      {medicineComponentErrors.KK_zusatz}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>{t("police_nr.label")}</label>
                  <input
                    defaultValue={get(medicineInfoState, "police_no", "")}
                    type="text"
                    name="police_no"
                    className="form-control"
                  />
                  {displayErrors ? (
                    <span style={{ color: "red" }}>
                      {medicineComponentErrors.police_no}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-12">
                <div className="title-form">
                  <h5>{t("accident_insurance.label")}</h5>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>{t("accident_insurance.label")}</label>
                  <input
                    defaultValue={get(
                      medicineInfoState,
                      "accident_insurance",
                      ""
                    )}
                    type="text"
                    name="accident_insurance"
                    className="form-control"
                  />
                  {displayErrors ? (
                    <span style={{ color: "red" }}>
                      {medicineComponentErrors.accident_insurance}
                    </span>
                  ) : null}
                </div>
              </div>
              {showMedExpert && showProblem && showDiagnose ? (
                <>{buttons()}</>
              ) : null}
            </div>
          </div>
        </form>
        {/* <form>
                    <div className="client-form">
                        <div className="row"> */}
        {!showMedExpert ? (
          <form
            onChange={(e) => {
              let req = valueHandler(e);
              dispatch(
                updateUserAction({
                  name: "med_expert." + req.name,
                  value: req.value,
                })
              );
            }}
          >
            <div className="client-form">
              <div className="row">
                <div className="col-md-12">
                  <div className="client-more client-more-cancel">
                    <h1>{t("add_medical_proffesional.label")}</h1>
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault
                          ? e.preventDefault()
                          : (e.returnValue = false);
                        toggleMedExpert(!showMedExpert);
                      }}
                    >
                      <i className="icon-cancel"></i>
                      {t("hide.label")}
                    </a>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>{t("type.label")}</label>
                    <Select
                      // text="Select function"
                      name="med_function"
                      selectedItem={get(
                        medExpertInfoState,
                        "med_function.id",
                        ""
                      )}
                      items={get(
                        selectItems,
                        "data.searchBaseModels.MedicalFunction",
                        []
                      )}
                      select={(e) => e}
                    />
                    {displayErrors ? (
                      <span style={{ color: "red" }}>
                        {medExpertComponentErrors.med_function}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>{t("first_name.label")}</label>
                    <input
                      defaultValue={get(medExpertInfoState, "name", "")}
                      type="text"
                      name="name"
                      className="form-control"
                    />
                    {displayErrors ? (
                      <span style={{ color: "red" }}>
                        {medExpertComponentErrors.name}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>{t("last_name.label")}</label>
                    <input
                      defaultValue={get(medExpertInfoState, "surname", "")}
                      type="text"
                      name="surname"
                      className="form-control"
                    />
                    {displayErrors ? (
                      <span style={{ color: "red" }}>
                        {medExpertComponentErrors.surname}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>{t("salutation.label")}</label>
                    <input
                      defaultValue={get(medExpertInfoState, "salutation", "")}
                      type="text"
                      name="salutation"
                      className="form-control"
                    />
                    {displayErrors ? (
                      <span style={{ color: "red" }}>
                        {medExpertComponentErrors.salutation}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>{t("address.label")}</label>
                    <input
                      defaultValue={get(medExpertInfoState, "street", "")}
                      type="text"
                      name="street"
                      className="form-control"
                    />
                    {displayErrors ? (
                      <span style={{ color: "red" }}>
                        {medExpertComponentErrors.street}
                      </span>
                    ) : null}
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                    <label>{t("postal.label")}</label>
                    <input
                      defaultValue={get(medExpertInfoState, "postal", "")}
                      type="text"
                      name="postal"
                      className="form-control"
                    />
                    {displayErrors ? (
                      <span style={{ color: "red" }}>
                        {medExpertComponentErrors.postal}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>{t("city.label")}</label>
                    <input
                      defaultValue={get(medExpertInfoState, "city", "")}
                      type="text"
                      name="city"
                      className="form-control"
                    />
                    {displayErrors ? (
                      <span style={{ color: "red" }}>
                        {medExpertComponentErrors.city}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>{t("phone.label")}</label>
                    <input
                      defaultValue={get(medExpertInfoState, "mobile", "")}
                      type="text"
                      name="mobile"
                      className="form-control"
                    />
                    {displayErrors ? (
                      <span style={{ color: "red" }}>
                        {medExpertComponentErrors.mobile}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>{t("email.label")}</label>
                    <input
                      defaultValue={get(medExpertInfoState, "email", "")}
                      type="email"
                      name="email"
                      className="form-control"
                    />
                    {displayErrors ? (
                      <span style={{ color: "red" }}>
                        {medExpertComponentErrors.email}
                      </span>
                    ) : null}
                  </div>
                </div>
                {!showMedExpert && showProblem && showDiagnose ? (
                  <>{buttons()}</>
                ) : null}
              </div>
            </div>
          </form>
        ) : (
          <div className="col-md-12">
            <div className="client-more">
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault
                    ? e.preventDefault()
                    : (e.returnValue = false);
                  toggleMedExpert(!showMedExpert);
                }}
              >
                <i className="icon-add"></i>
                {t("add_medical_proffesional.label")}
              </a>
            </div>
          </div>
        )}

        {!showDiagnose ? (
          <form
            onChange={(e) => {
              let req = valueHandler(e);
              dispatch(
                updateUserAction({
                  name: "diagnose." + req.name,
                  value: req.value,
                })
              );
            }}
          >
            <div className="client-form">
              <div className="row">
                <div className="col-md-12">
                  <div className="client-more client-more-cancel">
                    <h1>{t("add_diagnosis.label")}</h1>
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault
                          ? e.preventDefault()
                          : (e.returnValue = false);
                        toggleDiagnose(!showDiagnose);
                      }}
                    >
                      <i className="icon-cancel"></i>
                      {t("hide.label")}
                    </a>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>{t("type.label")}</label>
                    <input
                      defaultValue={get(diagnoseInfoState, "typ", "")}
                      type="text"
                      name="typ"
                      className="form-control"
                    />
                    {displayErrors ? (
                      <span style={{ color: "red" }}>
                        {diagnoseComponentErrors.typ}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>{t("description.label")}</label>
                    <textarea
                      defaultValue={get(diagnoseInfoState, "description", "")}
                      name="description"
                      className="form-control"
                      rows="5"
                    ></textarea>
                    {displayErrors ? (
                      <span style={{ color: "red" }}>
                        {diagnoseComponentErrors.description}
                      </span>
                    ) : null}
                  </div>
                </div>
                {showProblem && !showDiagnose ? <>{buttons()}</> : null}
              </div>
            </div>
          </form>
        ) : (
          <div className="col-md-12">
            <div className="client-more">
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault
                    ? e.preventDefault()
                    : (e.returnValue = false);
                  toggleDiagnose(!showDiagnose);
                }}
              >
                <i className="icon-add"></i>
                {t("add_diagnose.label")}
              </a>
            </div>
          </div>
        )}
        {!showProblem ? (
          <form
            onChange={(e) => {
              let req = valueHandler(e);
              dispatch(
                updateUserAction({
                  name: "problem." + req.name,
                  value: req.value,
                })
              );
            }}
          >
            <div className="client-form">
              <div className="row">
                <div className="col-md-12">
                  <div className="client-more client-more-cancel">
                    <h1>{t("add_problem.label")}</h1>
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault
                          ? e.preventDefault()
                          : (e.returnValue = false);
                        toggleProblem(!showProblem);
                      }}
                    >
                      <i className="icon-cancel"></i>
                      {t("hide.label")}
                    </a>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>{t("type.label")}</label>
                    <input
                      defaultValue={get(problemInfoState, "typ", "")}
                      type="text"
                      name="typ"
                      className="form-control"
                    />
                    {displayErrors ? (
                      <span style={{ color: "red" }}>
                        {problemComponentErrors.typ}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>{t("description.label")}</label>
                    <textarea
                      defaultValue={get(problemInfoState, "description", "")}
                      name="description"
                      className="form-control"
                      rows="5"
                    ></textarea>
                    {displayErrors ? (
                      <span style={{ color: "red" }}>
                        {problemComponentErrors.description}
                      </span>
                    ) : null}
                  </div>
                </div>
                {!showProblem ? <>{buttons()}</> : null}
              </div>
            </div>
          </form>
        ) : (
          <div className="col-md-12">
            <div className="client-more">
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault
                    ? e.preventDefault()
                    : (e.returnValue = false);
                  toggleProblem(!showProblem);
                }}
              >
                <i className="icon-add"></i>
                {t("add_problem.label")}
              </a>
            </div>
          </div>
        )}

        {/* </div>
                    </div>
                </form> */}
      </div>
      <ClientDisclaimer
        show={modal}
        close={() => toggleModal(false)}
        action={(e) => {
          e.preventDefault();
          toggleModal(false);
          submitFormHandler(
            updateUser,
            dispatch,
            singleUser.id,
            updateFlag,
            toggleModal
          );
        }}
      />
      {/* <Modal
                closeModal={() => toggleModal(false)}
                sendRequest={() => submitFormHandler(updateUser, dispatch, singleUser.id, updateFlag, toggleModal)}
                show={modal}
                oneButton
                text={t("client_creation_confirm.message")}
            /> */}
      <Modal
        show={secondModal}
        text={t("success.message")}
        history={props.history}
        path={"users"}
        id={singleUser.id}
        item={"user"}
        closeModal={() => dispatch(user({ secondModal: false }))}
      />
    </>
  );
};
// medExpertState, medicineState, problemState, diagnoseState
Medicine.propTypes = {
  changeMasterStateHandler: PropTypes.func,
  formChangeHandlerV2: PropTypes.func,
  medExpertState: PropTypes.object,
  medicineState: PropTypes.object,
  diagnoseState: PropTypes.object,
  problemState: PropTypes.object,
  history: PropTypes.object,
  modal: PropTypes.bool,
  update: PropTypes.bool,
};

export default withRouter(Medicine);
