import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useRouteMatch } from "react-router-dom";

import { FetchUserByCompleteRegistrationToken } from "./GraphQLActions";
import { showModal } from "../../store/global/global_actions";
import { removeToken } from "../../helpers";
import { resetAuthState } from "../../store/auth/auth_actions";

const CompleteUserRegistration = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const match = useRouteMatch();
  useEffect(() => {
    removeToken();
    dispatch(resetAuthState());
    dispatch(
      FetchUserByCompleteRegistrationToken({ token: match.params.token })
    )
      .then(() =>
        dispatch(
          showModal({
            type: 1,
            message: t("profile_loaded.message"),
            button: "Continue",
            link: "/auth/forgetpassword/" + match.params.token,
          })
        )
      )
      .catch((err) =>
        dispatch(
          showModal({
            type: 1,
            message: err.message,
            button: "Ok.",
            link: "/auth",
          })
        )
      );
  }, [dispatch, match.params.token, t]);

  return null;
};

export default CompleteUserRegistration;
