import React from "react";
import { Route, Switch, Redirect } from "react-router";

import Index from "./index.js";
import CompleteUserRegistration from "./CompleteUserRegistration";
import { Loading } from "../../components/UI/index.js";
import { useSelector } from "react-redux";

const SetUpProfileRoutes = () => {
  const globalState = useSelector((s) => s.globalState);

  return (
    <section className="container-fluid">
      <Loading loading={globalState.loading} />
      <Switch>
        <Route path="/new-user/complete-registration" component={Index} />
        <Route path="/new-user/:token" component={CompleteUserRegistration} />
        <Redirect to="/" />
      </Switch>
    </section>
  );
};

export default SetUpProfileRoutes;
