import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import NewUser from "../dashboard/User/NewUser";

const CompleteUserRegistrationIndex = () => {
  const userState = useSelector((s) => s.userState);
  const singleUser = userState.singleUser;
  const history = useHistory();

  useEffect(() => {
    if (!singleUser.id) {
      history.push("/");
    }
  }, [history, singleUser]);

  return <NewUser update />;
};

export default CompleteUserRegistrationIndex;
