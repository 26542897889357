/* eslint-disable react/no-unescaped-entities */
import React, { Fragment, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import ClientMenu from "../../../../components/UI/WizardNav/ClientMenu";
import PropTypes from "prop-types";
import get from "lodash/get";
import uuid from "uuid/v4";
import { useSelector, useDispatch } from "react-redux";
import { withRouter, Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "react-day-picker/lib/style.css";

import { Select, DatePicker, SearchSelect } from "../../../../components/UI";
import { searchBaseModels } from "../../../../resolvers/query/base";
import { valueHandler } from "./helpers";
import { updateUser as updateUserAction } from "../../../../store/user/user/user_actions";
import { SearchTrainers } from "../GraphQLActions";
// import DatePicker from "../../../../components/UI/DatePicker/DatePicker";
// import DatePickerComponen1t from "../../../../";

const Administration = () => {
  const { t } = useTranslation();
  const { singleUser, updateUser } = useSelector((s) => s.userState);
  const authState = useSelector((s) => s.authState);
  const dispatch = useDispatch();
  const history = useHistory();

  //States from redux
  const componentState = get(updateUser, "administration", {});

  //Errors for component
  let componentErrors = { ...get(updateUser, "administration.errors", {}) };
  let baseComponentErrors = {
    ...get(updateUser, "administration.isValidBase.errors", {}),
  };
  let { displayErrors } = updateUser.administration;
  displayErrors = displayErrors || updateUser.displayErrors;

  let PID = get(componentState, "PID", false);
  useEffect(() => {
    if (!PID) {
      dispatch(updateUserAction({ name: "administration.PID", value: uuid() }));
    }
  }, [PID, dispatch, componentState]);

  let selectItemsVariables = {
    people_group: true,
    administration_status: true,
    contact_channel: true,
    company: "true",
  };
  let hasCompany = get(updateUser, "company.id", null);

  if (hasCompany) selectItemsVariables["trainer"] = hasCompany;

  // let selectItems = {};
  let selectItems = useQuery(searchBaseModels, {
    variables: selectItemsVariables,
  });

  if (singleUser.completeProfileFlag) {
    history.push("personal");
  }

  /**
   *
   * @param {Event} e
   */
  const displayErrorsFunc = (e) => {
    let keys = [
      ...Object.keys(componentErrors),
      ...Object.keys(baseComponentErrors),
    ];
    console.log({ keys });
    if (keys.length > 0) {
      e.preventDefault();
      dispatch(
        updateUserAction({ name: "administration.displayErrors", value: true })
      );
    }
  };

  return (
    <>
      <ClientMenu
        componentName={"administration"}
        componentNumber={1}
        clickHandler={displayErrorsFunc}
      />

      {authState.role.id > 2 ? null : (
        <div className="container-fluid">
          <div className="client-form">
            <div className="row">
              <div className="col-md-12">
                <div className="client-form-title">
                  <h1>{t("assignment.label")}</h1>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>
                    {t("trainer.label")}
                    <span className="form-required">*</span>
                  </label>
                  <SearchSelect
                    searchKey="search"
                    value={
                      get(updateUser, "trainer", false) && {
                        id: updateUser.trainer.id,
                        name: `${updateUser.trainer.personal.name} ${updateUser.trainer.personal.surname}`,
                        companyName: updateUser.trainer.company.personal.name,
                        status: updateUser.trainer.status,
                      }
                    }
                    onChange={(e) =>
                      dispatch(
                        updateUserAction({ name: e.name, value: e.value })
                      )
                    }
                    getData={(variables) => dispatch(SearchTrainers(variables))}
                    formatLabels={(option) => {
                      return (
                        <Fragment>
                          {option.label}
                          {option.companyName && (
                            <span style={{ color: "red" }}>
                              {" "}
                              ({option.companyName})
                            </span>
                          )}
                          {option.status !== "ACTIVE" && (
                            <span style={{ color: "grey" }}> - INACTIVE</span>
                          )}
                        </Fragment>
                      );
                    }}
                    name="trainer"
                  />
                  {displayErrors ? (
                    <span style={{ color: "red" }}>
                      {baseComponentErrors.trainer}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="container-fluid">
        <form
          onChange={(e) => {
            let req = valueHandler(e);
            dispatch(
              updateUserAction({
                name: "administration." + req.name,
                value: req.value,
              })
            );
          }}
        >
          <div className="client-form">
            <div className="row">
              <div className="col-md-12">
                <div className="client-form-title">
                  <h1>{t("administration.label")}</h1>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>{t("pid.label")}</label>
                  <input
                    disabled
                    defaultValue={get(componentState, "PID", "")}
                    type="text"
                    name="title"
                    className="form-control"
                  />
                  {displayErrors ? (
                    <span style={{ color: "red" }}>{componentErrors.PID}</span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>
                    {t("people_group.label")}
                    {/* <span className="form-required">*</span> */}
                  </label>
                  <Select
                    name={"people_group"}
                    selectedItem={get(
                      componentState,
                      "people_group.id",
                      ""
                    ).toString()}
                    // text={"Select people group"}
                    items={get(
                      selectItems,
                      "data.searchBaseModels.PeopleGroup",
                      []
                    )}
                    select={(e) => e}
                  />
                  {displayErrors ? (
                    <span style={{ color: "red" }}>
                      {componentErrors.people_group}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>{t("status.label")}</label>
                  <Select
                    selectedItem={get(
                      componentState,
                      "status.id",
                      ""
                    ).toString()}
                    // text={"Select status"}
                    items={get(
                      selectItems,
                      "data.searchBaseModels.AdministrationStatus",
                      []
                    )}
                    select={(e) => e}
                    name={"status"}
                  />
                  {displayErrors ? (
                    <span style={{ color: "red" }}>
                      {componentErrors.status}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group date-custom date-custom-label">
                  <label>{t("contact_date.label")}</label>
                  <DatePicker
                    placeholder={"DD-MM-YYYY"}
                    date={get(componentState, "contact_date", "")}
                    select={(e) =>
                      dispatch(
                        updateUserAction({
                          name: "administration.contact_date",
                          value: e,
                        })
                      )
                    }
                  />
                  {displayErrors ? (
                    <span style={{ color: "red" }}>
                      {componentErrors.contact_date}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>{t("contact_channel.label")}</label>
                  <Select
                    selectedItem={get(
                      componentState,
                      "contact_channel.id",
                      ""
                    ).toString()}
                    // text={"Select communication channel"}
                    items={get(
                      selectItems,
                      "data.searchBaseModels.ContactChannel",
                      []
                    )}
                    select={(e) => e}
                    name={"contact_channel"}
                  />
                  {displayErrors ? (
                    <span style={{ color: "red" }}>
                      {componentErrors.contact_channel}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>{t("found_from.label")}</label>
                  <input
                    defaultValue={get(componentState, "found_from", "")}
                    type="text"
                    name="found_from"
                    className="form-control"
                  />
                  {displayErrors ? (
                    <span style={{ color: "red" }}>
                      {componentErrors.found_from}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>{t("referenced_by.label")}</label>
                  <input
                    defaultValue={get(componentState, "referenced_by", "")}
                    type="text"
                    name="referenced_by"
                    className="form-control"
                  />
                  {displayErrors ? (
                    <span style={{ color: "red" }}>
                      {componentErrors.referenced_by}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>{t("remark_contact.label")}</label>
                  <input
                    defaultValue={get(componentState, "remark_contact", "")}
                    type="text"
                    name="remark_contact"
                    className="form-control"
                  />
                  {displayErrors ? (
                    <span style={{ color: "red" }}>
                      {componentErrors.remark_contact}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group date-custom date-custom-label">
                  <label>{t("notify_on.label")}</label>
                  <DatePicker
                    placeholder={"DD-MM-YYYY"}
                    date={get(componentState, "notify_on", "")}
                    select={(e) =>
                      dispatch(
                        updateUserAction({
                          name: "administration.notify_on",
                          value: e,
                        })
                      )
                    }
                  />
                  {displayErrors ? (
                    <span style={{ color: "red" }}>
                      {componentErrors.notify_on}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group date-custom date-custom-label">
                  <label>{t("first_visit.label")}</label>
                  <DatePicker
                    placeholder={"DD-MM-YYYY"}
                    date={get(componentState, "first_visit", "")}
                    select={(e) =>
                      dispatch(
                        updateUserAction({
                          name: "administration.first_visit",
                          value: e,
                        })
                      )
                    }
                  />
                  {displayErrors ? (
                    <span style={{ color: "red" }}>
                      {componentErrors.first_visit}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group date-custom date-custom-label">
                  <label>{t("death_date.label")}</label>
                  <DatePicker
                    placeholder={"DD-MM-YYYY"}
                    date={get(componentState, "death_date", "")}
                    select={(e) =>
                      dispatch(
                        updateUserAction({
                          name: "administration.death_date",
                          value: e,
                        })
                      )
                    }
                  />
                  {displayErrors ? (
                    <span style={{ color: "red" }}>
                      {componentErrors.death_date}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>{t("remark_date.label")}</label>
                  <input
                    defaultValue={get(componentState, "remark_death", "")}
                    type="text"
                    name="remark_death"
                    className="form-control"
                  />
                  {displayErrors ? (
                    <span style={{ color: "red" }}>
                      {componentErrors.remark_death}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>{t("vip.label")}</label>
                  <div className="select-custom select-max-width">
                    <select
                      name={"VIP"}
                      defaultValue={
                        get(componentState, "VIP", false) ? "true" : "false"
                      }
                      className="form-control"
                    >
                      <option value={"true"}>True</option>
                      <option value={"false"}>False</option>
                    </select>
                  </div>
                  {displayErrors ? (
                    <span style={{ color: "red" }}>{componentErrors.VIP}</span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label>{t("remark_administration.label")}</label>
                  <textarea
                    defaultValue={get(componentState, "comment", "")}
                    type="text"
                    name="comment"
                    className="form-control"
                    rows="5"
                  ></textarea>
                  {displayErrors ? (
                    <span style={{ color: "red" }}>
                      {componentErrors.comment}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-12">
                <div className="client-more">
                  <a href="#">
                    {/* <i className="icon-add"></i>Administratives "hinzufügen" */}
                  </a>
                </div>
              </div>
              <div className="col-md-12 d-flex justify-content-end">
                <div className="btn-right">
                  <Link
                    to={"/dashboard/users"}
                    className="btn btn-cancel btn-180"
                  >
                    {t("cancel.label")}
                  </Link>
                  <Link
                    onClick={displayErrorsFunc}
                    to={"personal"}
                    href="#"
                    className="btn btn-primary btn-180"
                  >
                    {t("next.label")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

Administration.propTypes = {
  changeMasterStateHandler: PropTypes.func,
  formChangeHandler: PropTypes.func,
  state: PropTypes.object,
  history: PropTypes.object,
  update: PropTypes.bool,
};

export default withRouter(Administration);
