import React, { useEffect } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import {
  Administration,
  Finance,
  Medicine,
  Sport,
  Work,
  Personal,
  Private,
} from "./Forms/index";
import {
  prepareUserUpdate,
  updateUser as updateUserAction,
} from "../../../store/user/user/user_actions";

const NewUser = (props) => {
  const { updateUser } = useSelector((s) => s.userState);
  const authState = useSelector((s) => s.authState);
  const dispatch = useDispatch();
  let path = props.location.pathname.split("/");
  let lastPath = path[path.length - 1];
  useEffect(() => {
    // if (path.includes("new-user") && updateUser.id) {
    //     dispatch(user({ updateUser: {} }));
    // } else if (!path.includes("new-user") && !updateUser.id) {
    //     dispatch(prepareUserUpdate());
    // }
    if (
      !updateUser.id &&
      (path.includes("edit-user") || path.includes("complete-registration"))
    )
      dispatch(prepareUserUpdate());

    if (
      lastPath === "new-user" ||
      lastPath === "edit-user" ||
      lastPath === "complete-registration"
    ) {
      props.history.push(lastPath + "/administration");
    }

    if (authState.role.id === 3) {
      dispatch(updateUserAction({ name: "trainer", value: authState }));
    }
  }, []);

  return (
    <section
      className="main-content"
      style={
        updateUser.complete_profile_token
          ? {
              padding: "2rem",
              width: "100%",
            }
          : {}
      }
    >
      <Switch>
        <Route
          exact
          path={props.match.url + "/medicine"}
          component={Medicine}
        />
        <Route exact path={props.match.url + "/work"} component={Work} />
        <Route exact path={props.match.url + "/sport"} component={Sport} />
        <Route exact path={props.match.url + "/finance"} component={Finance} />
        <Route exact path={props.match.url + "/private"} component={Private} />
        <Route
          exact
          path={props.match.url + "/personal"}
          component={Personal}
        />
        <Route
          exact
          path={props.match.url + "/administration"}
          component={Administration}
        />
      </Switch>
    </section>
  );
};

NewUser.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
};

export default withRouter(NewUser);
