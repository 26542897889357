/* eslint-disable react/no-unescaped-entities */
import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "@apollo/react-hooks";
import get from "lodash/get";
import { useTranslation } from "react-i18next";
import { withRouter, Link, useHistory } from "react-router-dom";

import ClientMenu from "../../../../components/UI/WizardNav/ClientMenu";
import { searchBaseModels } from "../../../../resolvers/query/base";
import { Select } from "../../../../components/UI";
import { valueHandler } from "./helpers";
import { updateUser as updateUserAction } from "../../../../store/user/user/user_actions";

const Finance = () => {
    const { t } = useTranslation();
    const { updateUser, singleUser } = useSelector(s => s.userState);
    const dispatch = useDispatch();
    const history = useHistory();

    //Redux state
    const financeInfoState = get(updateUser, "finance", {});
    const ahvInfoState = get(updateUser, "ahv", {});

    //Errors for component
    let { displayErrors } = updateUser.finance;
    displayErrors = displayErrors || updateUser.displayErrors;
    let financeComponentErrors = get(updateUser, "finance.errors", {});
    let ahvComponentErrors = get(updateUser, "ahv.errors", {});

    let selectItems = useQuery(searchBaseModels, { variables: { country: true } });

    if (singleUser.completeProfileFlag) {
        history.push("sport");
    }

    /**
     *
     * @param {Event} e
     */
    const displayErrorsFunc = e => {
        let keys = [...Object.keys(financeComponentErrors), ...Object.keys(ahvComponentErrors)];
        if (keys.length > 0) {
            e.preventDefault();
            dispatch(updateUserAction({ name: "finance.displayErrors", value: true }));
        }
    };

    return (
        <>
            <ClientMenu componentName={"finance"} componentNumber={4} clickHandler={displayErrorsFunc} />
            <div className="container-fluid">
                <form
                    onChange={e => {
                        let req = valueHandler(e);
                        dispatch(updateUserAction({ name: "finance." + req.name, value: req.value }));
                    }}
                >
                    <div className="client-form">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="client-form-title">
                                    <h1>{t("finance.label")}</h1>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="title-form">
                                    <h5>{t("bank_account.label")}</h5>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>{t("iban.label")}</label>
                                    <input
                                        defaultValue={get(financeInfoState, "IBAN", "")}
                                        type="text"
                                        name="IBAN"
                                        className="form-control"
                                    />
                                    {displayErrors ? (
                                        <span style={{ color: "red" }}>{financeComponentErrors.IBAN}</span>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>{t("account.label")}</label>
                                    <input
                                        defaultValue={get(financeInfoState, "account_no", "")}
                                        type="text"
                                        name="account_no"
                                        className="form-control"
                                    />
                                    {displayErrors ? (
                                        <span style={{ color: "red" }}>{financeComponentErrors.account_no}</span>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>{t("clearing_nr.label")}</label>
                                    <input
                                        defaultValue={get(financeInfoState, "clearing_no", "")}
                                        type="text"
                                        name="clearing_no"
                                        className="form-control"
                                    />
                                    {displayErrors ? (
                                        <span style={{ color: "red" }}>{financeComponentErrors.clearing_no}</span>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>{t("bic.label")}</label>
                                    <input
                                        defaultValue={get(financeInfoState, "BIC", "")}
                                        type="text"
                                        name="BIC"
                                        className="form-control"
                                    />
                                    {displayErrors ? (
                                        <span style={{ color: "red" }}>{financeComponentErrors.BIC}</span>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>{t("bank_name.label")}</label>
                                    <input
                                        defaultValue={get(financeInfoState, "bank_name", "")}
                                        type="text"
                                        name="bank_name"
                                        className="form-control"
                                    />
                                    {displayErrors ? (
                                        <span style={{ color: "red" }}>{financeComponentErrors.bank_name}</span>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>{t("bank_street.label")}</label>
                                    <input
                                        defaultValue={get(financeInfoState, "bank_street", "")}
                                        type="text"
                                        name="bank_street"
                                        className="form-control"
                                    />
                                    {displayErrors ? (
                                        <span style={{ color: "red" }}>{financeComponentErrors.bank_street}</span>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>{t("bank_postal.label")}</label>
                                    <input
                                        defaultValue={get(financeInfoState, "bank_postal", "")}
                                        type="text"
                                        name="bank_postal"
                                        className="form-control"
                                    />
                                    {displayErrors ? (
                                        <span style={{ color: "red" }}>{financeComponentErrors.bank_postal}</span>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>{t("bank_city.label")}</label>
                                    <input
                                        defaultValue={get(financeInfoState, "bank_city", "")}
                                        type="text"
                                        name="bank_city"
                                        className="form-control"
                                    />
                                    {displayErrors ? (
                                        <span style={{ color: "red" }}>{financeComponentErrors.bank_city}</span>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>{t("bank_country.label")}</label>
                                    <Select
                                        name="bank_country"
                                        items={get(selectItems, "data.searchBaseModels.Country", [])}
                                        selectedItem={get(financeInfoState, "bank_country.id", "").toString()}
                                        // text={"Select country"}
                                        select={e => e}
                                    />
                                    {displayErrors ? (
                                        <span style={{ color: "red" }}>{financeComponentErrors.country}</span>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>{t("bank_additional_address.label")}</label>
                                    <input
                                        defaultValue={get(financeInfoState, "bank_address_addition", "")}
                                        type="text"
                                        name="bank_address_addition"
                                        className="form-control"
                                    />
                                    {displayErrors ? (
                                        <span style={{ color: "red" }}>
                                            {financeComponentErrors.bank_address_addition}
                                        </span>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>{t("remark_account.label")}</label>
                                    <input
                                        defaultValue={get(financeInfoState, "comment", "")}
                                        type="text"
                                        name="comment"
                                        className="form-control"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <form
                    onChange={e => {
                        let req = valueHandler(e);
                        dispatch(updateUserAction({ name: "ahv." + req.name, value: req.value }));
                    }}
                >
                    <div className="client-form">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="title-form">
                                    <h5>{t("insurance.label")}</h5>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>{t("ahv_nr.label")}</label>
                                    <input
                                        defaultValue={get(ahvInfoState, "AHV", "")}
                                        type="text"
                                        name="AHV"
                                        className="form-control"
                                    />
                                    {displayErrors ? (
                                        <span style={{ color: "red" }}>{ahvComponentErrors.AHV}</span>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>{t("remark_ahv_nr.label")}</label>
                                    <input
                                        defaultValue={get(ahvInfoState, "remark_ahv", "")}
                                        type="text"
                                        name="remark_ahv"
                                        className="form-control"
                                    />
                                    {displayErrors ? (
                                        <span style={{ color: "red" }}>{ahvComponentErrors.remark_ahv}</span>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>{t("other_comments.label")}</label>
                                    <textarea
                                        defaultValue={get(ahvInfoState, "comment", "")}
                                        name="comment"
                                        className="form-control"
                                        rows="5"
                                    ></textarea>
                                    {displayErrors ? (
                                        <span style={{ color: "red" }}>{ahvComponentErrors.comment}</span>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-md-12 d-flex justify-content-end">
                                <div className="btn-right">
                                    <Link to="private" className="btn btn-cancel btn-180">
                                        {t("back.label")}
                                    </Link>
                                    <Link onClick={displayErrorsFunc} to="sport" className="btn btn-primary btn-180">
                                        {t("next.label")}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

Finance.propTypes = {
    changeMasterStateHandler: PropTypes.func,
    formChangeHandler: PropTypes.func,
    financeState: PropTypes.object,
    ahvState: PropTypes.object,
    history: PropTypes.object
};

export default withRouter(Finance);
